.constrained-720 {
  width: 1180px;
}


@media only screen and (max-width: 1200px) {
  .constrained-720 {
    width: 100%;
  }
}
