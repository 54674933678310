.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

hr {
  border: 1px solid #e5e5e5;
}

body {
  font-family: "Poppins", sans-serif;
}

/* -------------------- Typography --------------------*/

/*h1      { font-size: 2.5em;       !* 2*16 = 32 *!         }*/
/*h2      { font-size: 2em;     !* 1.5*16 = 24 *!       }*/
/*h3      { font-size: 1.75em;    !* 1.17*16 = 18.72 *!   }*/
/*h4      { font-size: 1.5em;       !* 1*16 = 16 *!         }*/
/*h5      { font-size: 1.15em;    !* 0.83*16 = 13.28 *!   }*/
/*h6      { font-size: 1em;    !* 0.75*16 = 12 *!      }*/

h1 {
  font-size: 2em; /* 2*16 = 32 */
}

h2 {
  font-size: 1.72em; /* 1.5*16 = 24 */
}

h3 {
  font-size: 1.5em; /* 1.17*16 = 18.72 */
}

h4 {
  font-size: 1.25em; /* 1*16 = 16 */
}

h5 {
  font-size: 1.12em; /* 0.83*16 = 13.28 */
}

h6 {
  font-size: 1em; /* 0.75*16 = 12 */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: rgb(78, 92, 110);
  margin: 0 0 15px 0;
  font-family: "Poppins", sans-serif;
  /*font-family: 'Ropa Sans', sans-serif;*/
  line-height: 1.2;
  /*font-family: 'IBM Plex Sans', sans-serif;*/
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  margin-bottom: 15px;
  /*font-family: 'Ropa Sans', sans-serif;*/
}

.level-1 {
  color: #464457;
}

.level-2 {
  /*color: #65697d;*/
  color: #6c7293;
}

.level-3 {
  color: #898fad;
  /*color: #a7abc3;*/
}

.level-4 {
  color: #adb1c7;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-5 {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-break: break-word;
}

.display-6 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-break: break-word;
}

.display-7 {
  font-size: 1.35rem;
  font-weight: 300;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-break: break-word;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

/* -------------------- Text colors --------------------*/

.text-blue {
  color: #3d94fb;
}

.text-token-blue {
  color: #009bc3;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-grey {
  color: grey;
}

.text-green {
  color: #4caf50;
}

.text-red {
  color: #f44336;
}

.text-orange {
  color: #f28e38;
}

.text-yellow {
  color: #ffb822;
}

.text-underlined {
  text-decoration: underline;
}

.text-bold {
  font-weight: 600;
}

/* -------------------- Description section --------------------*/
.description-content {
  padding: 1.5rem;
  border-left: 4px solid #e2e5ec;
  background-color: #f7f8fa;
}

/* -------------------- Divider with text --------------------*/
.divider-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-with-text > span:first-child {
  width: 100%;
  height: 1px;
  flex: 1;
  background: #ebecf1;
  display: inline-block;
}

.divider-with-text > span:last-child {
  width: 100%;
  height: 1px;
  flex: 1;
  background: #ebecf1;
  display: inline-block;
}

/* -------------------- Card footer --------------------*/
.card-footer {
  padding: 25px;
  border-top: 1px solid #ebedf2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* -------------------- Trigger --------------------*/

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: #fff;
}

.trigger:hover {
  color: #fff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

@media only screen and (max-width: 768px) {
  .ant-layout-header {
    padding: 0 !important;
  }
}

.ant-layout-content {
  background: #f4f7fa;
}

.ladda-button {
  background: #3c90df;
}

.ladda-button:hover {
  background: #1890ff;
}

.ladda-button[disabled],
.ladda-button[disabled]:hover,
.ladda-button[data-loading],
.ladda-button[data-loading]:hover {
  cursor: default;
  background-color: #3c90df;
}

/*Flex styles*/

.centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.vertical-start-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.no-flex-wrap {
  flex-wrap: nowrap;
}

.h-centered-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.h-justified-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.h-start-flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.h-end-flex {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.pagination-h-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  align-items: flex-start;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.responsive-flex-image {
  margin: 15px;
  width: 320px;
  height: 270px;
}

@media only screen and (max-width: 600px) {
  .responsive-flex-image {
    margin: 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .responsive-flex-image {
    margin: 0 auto;
    padding: 15px;
    width: 70%;
  }
}

/* -------------------- Image center and cropped --------------------*/
.centered-and-cropped {
  object-fit: cover;
}

.profile-pic {
  height: 100%;
  width: 100%;
}

.rounded {
  border-radius: 50%;
}

/* ActivePath needed to position the button. Adjust the width as needed */
.profile-pic-container {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

/* Style the button and place it in the middle of the container/image */
.change-profile-pic {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -0%);
  height: 50%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  border-bottom-left-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
  font-size: 1.2em;
  cursor: pointer;
  border: 0;
}

.abs-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.change-profile-pic:hover {
  opacity: 1;
  transition: all 0.3s ease;
}

.change-profile-pic:focus {
  outline: none;
}

.pointer-change-on-hover:hover {
  cursor: pointer;
}

.color-change-on-hover:hover {
  color: #0284ca;
}

.faded {
  color: #a7abc3 !important;
}

.fade {
  opacity: 0.25;
}

.text-muted {
  color: #a7abc3 !important;
}

.text-medium {
  font-size: 1.12em;
}

.text-big {
  font-size: 1.2em;
}

.text-biggest {
  font-size: 1.35em;
}

.text-mini {
  font-size: 0.8em;
}

.text-alternate {
  font-family: "Roboto", sans-serif;
}

/*Margin and padding helpers*/
.small-padding {
  padding: 15px;
}

.small-vertical-padding {
  padding: 15px 0;
}

.medium-padding {
  padding: 30px;
}

.medium-vertical-padding {
  padding: 36px 0;
}

.default-margin {
  margin-bottom: 15px;
}

.default-margin-closer {
  margin-bottom: 7px;
}

.default-margin-farther {
  margin-bottom: 30px;
}

.no-margin {
  margin: 0;
}

.small-vertical-margin {
  margin: 5px 0;
}

.medium-vertical-margin {
  margin: 15px 0;
}

@media only screen and (min-width: 768px) {
  .ant-table-body {
    overflow-x: auto !important;
  }
}

.loading-container {
  height: 100vh;
  width: 100vw;
  background: white;
  position: fixed;
  opacity: 1;
}

.rounded-btn {
  border-radius: 5px;
}

._loading_overlay_wrapper {
  background: white;
}

._loading_overlay_overlay {
  background: white;
}

._loading_overlay_content {
  background: white;
}

/* -------------------------- Input ------------------------ */
.responsive-input {
  width: 250px;
}

.small-responsive-input {
  width: 120px;
}

@media only screen and (max-width: 600px) {
  .responsive-input,
  .small-responsive-input {
    width: 100%;
  }
}

/* -------------------- Table --------------------*/
.bg-white {
  background: white;
}

.diff-colored-bg-1 {
  background: #fafafa;
}

.colored-col-bg-1 {
  background: #b3e5fc;
}

.colored-col-bg-2 {
  background: #90a4ae;
}

.colored-col-bg-3 {
  background: #eff8fc;
}

.colored-col-bg-4 {
  background: #fcfcfc;
}

/* -------------------- Grid --------------------*/
.investment-cards-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Where the magic happens */
  grid-auto-rows: auto;
  grid-gap: 30px;
}

.stat-cards-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Where the magic happens */
  grid-auto-rows: auto;
  grid-gap: 30px;
}

/* -------------------- Table --------------------*/
.alternate-table {
  width: 100%;
}

.alternate-table tr:nth-child(odd) {
  background-color: #fafafa;
}

.alternate-table th {
  background: #fefefe;
}

.alternate-table td,
th {
  padding: 10px;
}

.alternate-table th {
  font-weight: 500;
}

.expanded-cell {
  width: 100%; /* this will shrink other cells */
}
​ .bordered-right {
  border-right: 1px solid #dee2e6;
}

.bordered-top {
  border-top: 1px solid #dee2e6;
}

.bordered-bottom {
  border-bottom: 1px solid #dee2e6;
}

@media only screen and (max-width: 600px) {
  .bordered-right {
    border-right: 0;
  }
}

/* -------------------- Single alternat etable style --------------------*/
.single-alternate-table-like {
  background-color: #fafafa;
  width: 100%;
  padding: 10px;
}

/* -------------------- Overflow --------------------*/
.overflow-word {
  overflow-wrap: break-word;
  word-break: break-word;
}

/* -------------------- Custom inner card --------------------*/
.custom-inner-card {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
}

@media only screen and (max-width: 600px) {
  .custom-inner-card {
    /*border: 0;*/
  }
}

/* -------------------- Filter inputs --------------------*/
.filter-inputs {
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .filter-inputs {
    width: 100%;
  }
}

/* -------------------- print --------------------*/
@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 15mm 0 0 0;
  }

  body {
    /*padding-top:5mm;*/
  }
}

.print-table {
  font-family: Roboto, sans-serif;
  font-size: 10px;
  width: 95%;
}

.print-table td,
.print-table th {
  border: 1px solid #ddd;
  border-collapse: collapse;
  padding: 5px;
}

.print-table td div {
  padding: 7px;
}

.print-table tr:nth-child(even) {
  background-color: #fafafa;
}

.make-ant-input {
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 1em;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

/* -------------------- hash or addresses --------------------*/
.hash-address {
  /*background-color: #FAFAFA;*/
  /*padding: 10px;*/
}

.inverted-div {
  background: #cfd8dc;
  color: black;
  /*font-weight: 600;*/
}

.inner-divs div {
  overflow: visible !important;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  vertical-align: middle;
}

.content-child {
  /*flex: 1 0 auto;*/
}

.my-svgs {
  fill: #009bc3;
}

.swal-title {
  font-size: 1.5rem !important;
  overflow-wrap: break-word;
  word-break: break-word;
}

.swal-small-title {
  font-size: 1.2rem !important;
  overflow-wrap: break-word;
  word-break: break-word;
  /*font-weight: 400;*/
}

/* -------------------- badge --------------------*/
.badge {
  padding: 6px 12px;
  line-height: 15px;
  font-weight: 400;
  min-width: 90px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid;
}

.whitespace-nowrap {
  white-space: nowrap;
}

/* -------------------- glowing blinking --------------------*/
.led-box {
  top: 35%;
  left: 10%;
  transform: translate(-50%, -35%);
  position: absolute;
}

.led-green {
  margin: 0 auto;
  width: 12px;
  height: 12px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 12px;
  -webkit-animation: blinkGreen 1s infinite;
  -moz-animation: blinkGreen 1s infinite;
  -ms-animation: blinkGreen 1s infinite;
  -o-animation: blinkGreen 1s infinite;
  animation: blinkGreen 1s infinite;
}

@-webkit-keyframes blinkGreen {
  from {
    background-color: #abff00;
  }
  50% {
    background-color: #43a047;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 0;
  }
  to {
    background-color: #abff00;
  }
}
@-moz-keyframes blinkGreen {
  from {
    background-color: #abff00;
  }
  50% {
    background-color: #43a047;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 0;
  }
  to {
    background-color: #abff00;
  }
}
@-ms-keyframes blinkGreen {
  from {
    background-color: #abff00;
  }
  50% {
    background-color: #43a047;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 0;
  }
  to {
    background-color: #abff00;
  }
}
@-o-keyframes blinkGreen {
  from {
    background-color: #abff00;
  }
  50% {
    background-color: #43a047;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 0;
  }
  to {
    background-color: #abff00;
  }
}
@keyframes blinkGreen {
  from {
    background-color: #abff00;
  }
  50% {
    background-color: #1b5e20;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 0;
  }
  to {
    background-color: #abff00;
  }
}

/* -------------------- zig zag --------------------*/

.zag-out:after {
  top: 100%;
  background: linear-gradient(135deg, white 33.333%, transparent 0),
    linear-gradient(-135deg, white 33.333%, transparent 0);
}

.zag-in:after {
  bottom: 0;
  background: linear-gradient(45deg, white 33.333%, transparent 0),
    linear-gradient(-45deg, white 33.333%, transparent 0);
}

.zig {
  position: relative;
  background: white;
  /* Anything but 'static' will work here. */
}
.zig:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.5em;
  /*desired tooth height*/
  background-size: 1em 100%;
  /*width (1st num) must be twice tooth height*/
  background-position: center;
  /*optional: used for symmetrical edges*/
}

.blue-card {
  background-image: linear-gradient(45deg, #1c65c9 0%, #2c80ff 100%);
  color: #fff;
}

/* -------------------- table --------------------*/
.data-details-list {
  border-radius: 4px;
  border: 1px solid #d2dde9;
}

.data-transaction-list {
  border-radius: 4px;
  border: 1px solid #d2dde9;
}

.data-transaction-item {
  border-bottom: 1px solid #d2dde9;
  display: inline-block;
  width: 100%;
}

.data-details-head {
  font-weight: 500;
  color: #758698;
  line-height: 24px;
  padding: 15px 15px 2px 15px;
  width: 100%;
  display: inline-block;
}

@media (min-width: 576px) {
  .data-details-head {
    width: 190px;
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .data-details-head {
    width: 190px;
  }
}

.data-details-list div:last-child .data-details-des {
  border-bottom: none;
}

.data-transaction-list div div:last-child .data-transaction-item {
  border-bottom: none;
}

.data-details-des {
  color: #495463;
  line-height: 24px;
  padding: 2px 15px 15px 15px;
  border-bottom: 1px solid #d2dde9;
  display: inline-block;
  width: 100%;
}

@media (min-width: 576px) {
  .data-details-des {
    border-top: none;
    line-height: 24px;
    border-left: 1px solid #d2dde9;
    width: calc(100% - 190px);
    padding: 15px;
  }
}

.placeholder {
  width: 36px;
  height: 36px;
  margin-right: 7px;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-size: contain;
  background: white;
  /*background-image: url("./static/profile-pic-placeholder.jpeg");*/
}
