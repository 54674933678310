/* Overrides */

.ant-table-thead > tr > th {
  /*background: rgba(6, 103, 208, .8) !important;*/
  /*color: #009bc3 !important;*/
}

.ant-table-thead > tr > th > div {
  /*color: white;*/

}

.ant-input {
  /*border-radius: 0 !important;*/
}

#nprogress .bar {
  background: #f28e38 !important;

  z-index: 1031;
  top: 0 !important;
  left: 0;

  width: 100%;
  height: 5px !important;
}

/* Fancy blur effect */
#nprogress .peg {
  /*box-shadow: 0 0 10px #f28e38, 0 0 5px #29d !important;*/
  box-shadow: none !important;
}

/* Fancy blur effect */
#nprogress .spinner {
  display: none !important;
}

.ant-divider-horizontal {
  margin: 0 0 15px 0 !important;
}

.ant-progress-inner {
  display: inline-block;
  width: 100%;
  background-color: #E0E0E0 !important;
  border-radius: 100px;
  vertical-align: middle;
  position: relative;
}

.ant-tag {
  height: 22px !important;
}

.ant-calendar-picker {
  max-width: 200px;
}

@media only screen and (max-width: 600px) {
  .ant-radio-button-wrapper {
    padding: 0 5px !important;
  }

  .ant-steps-item-title {
    width: 100%;
  }
}


.ant-skeleton-paragraph {
  display: none;
}

/* -------------------- tabs --------------------*/
/* -------------------- tabs container --------------------*/
#tabs-card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

#tabs-card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #F5F5F5;
  padding: 15px;
}

#tabs-card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #F5F5F5;
}

#tabs-card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

#tabs-card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #F5F5F5;
  background: #F5F5F5;
}

.ant-tabs-tabpane-inactive {
  /*display: none;*/
}


/* -------------------- Input number --------------------*/
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.ant-input-number-handler-wrap {
  display: none;
}

.side-drawer .ant-drawer-header {
  background: #009bc3;
  border-radius: 0;
}

.ant-tabs {
  /*font-size: 14px !important;*/
}

.ant-table {
  /*font-size: 13px !important;*/
}


/* -------------------- message --------------------*/
.ant-message-notice-content {
  overflow-wrap: break-word;
  word-break: break-word;
}

/* -------------------- Alerts --------------------*/
.ant-alert-close-icon {
  font-size: 16px !important;
  font-weight: bold !important;
  right: 10px !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: green !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: green !important;
}

.ant-steps-item-finish > .ant-steps-item-tail::after {
  background-color: #52c41a !important;
}

.ant-layout-content {
  /*display: flex;*/
}

/* -------------------- slider --------------------*/
#custom-progress span, #custom-progress div {
  cursor: default !important;
}

#custom-progress .ant-slider-track {
  background: #009AC2 !important;
}

#custom-progress .ant-slider-handle, #custom-progress  .ant-slider-dot {
  border-color: #009AC2 !important;
}

