html {
  font-size: 13px !important;
}

body {
  margin: 0;
  padding: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
  /*sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F7FA !important;
  font-weight: 300;
  font-family: 'Poppins', sans-serif !important;
  font-size: 13px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}


.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

hr {
  border: 1px solid #e5e5e5;
}

body {
  font-family: "Poppins", sans-serif;
}

/* -------------------- Typography --------------------*/

/*h1      { font-size: 2.5em;       !* 2*16 = 32 *!         }*/
/*h2      { font-size: 2em;     !* 1.5*16 = 24 *!       }*/
/*h3      { font-size: 1.75em;    !* 1.17*16 = 18.72 *!   }*/
/*h4      { font-size: 1.5em;       !* 1*16 = 16 *!         }*/
/*h5      { font-size: 1.15em;    !* 0.83*16 = 13.28 *!   }*/
/*h6      { font-size: 1em;    !* 0.75*16 = 12 *!      }*/

h1 {
  font-size: 2em; /* 2*16 = 32 */
}

h2 {
  font-size: 1.72em; /* 1.5*16 = 24 */
}

h3 {
  font-size: 1.5em; /* 1.17*16 = 18.72 */
}

h4 {
  font-size: 1.25em; /* 1*16 = 16 */
}

h5 {
  font-size: 1.12em; /* 0.83*16 = 13.28 */
}

h6 {
  font-size: 1em; /* 0.75*16 = 12 */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  color: rgb(78, 92, 110);
  margin: 0 0 15px 0;
  font-family: "Poppins", sans-serif;
  /*font-family: 'Ropa Sans', sans-serif;*/
  line-height: 1.2;
  /*font-family: 'IBM Plex Sans', sans-serif;*/
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  margin-bottom: 15px;
  /*font-family: 'Ropa Sans', sans-serif;*/
}

.level-1 {
  color: #464457;
}

.level-2 {
  /*color: #65697d;*/
  color: #6c7293;
}

.level-3 {
  color: #898fad;
  /*color: #a7abc3;*/
}

.level-4 {
  color: #adb1c7;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-5 {
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-break: break-word;
}

.display-6 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-break: break-word;
}

.display-7 {
  font-size: 1.35rem;
  font-weight: 300;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-break: break-word;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

/* -------------------- Text colors --------------------*/

.text-blue {
  color: #3d94fb;
}

.text-token-blue {
  color: #009bc3;
}

.text-white {
  color: #ffffff;
}

.text-black {
  color: #000000;
}

.text-grey {
  color: grey;
}

.text-green {
  color: #4caf50;
}

.text-red {
  color: #f44336;
}

.text-orange {
  color: #f28e38;
}

.text-yellow {
  color: #ffb822;
}

.text-underlined {
  text-decoration: underline;
}

.text-bold {
  font-weight: 600;
}

/* -------------------- Description section --------------------*/
.description-content {
  padding: 1.5rem;
  border-left: 4px solid #e2e5ec;
  background-color: #f7f8fa;
}

/* -------------------- Divider with text --------------------*/
.divider-with-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.divider-with-text > span:first-child {
  width: 100%;
  height: 1px;
  flex: 1 1;
  background: #ebecf1;
  display: inline-block;
}

.divider-with-text > span:last-child {
  width: 100%;
  height: 1px;
  flex: 1 1;
  background: #ebecf1;
  display: inline-block;
}

/* -------------------- Card footer --------------------*/
.card-footer {
  padding: 25px;
  border-top: 1px solid #ebedf2;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* -------------------- Trigger --------------------*/

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
  color: #fff;
}

.trigger:hover {
  color: #fff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

@media only screen and (max-width: 768px) {
  .ant-layout-header {
    padding: 0 !important;
  }
}

.ant-layout-content {
  background: #f4f7fa;
}

.ladda-button {
  background: #3c90df;
}

.ladda-button:hover {
  background: #1890ff;
}

.ladda-button[disabled],
.ladda-button[disabled]:hover,
.ladda-button[data-loading],
.ladda-button[data-loading]:hover {
  cursor: default;
  background-color: #3c90df;
}

/*Flex styles*/

.centered-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.vertical-start-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.no-flex-wrap {
  flex-wrap: nowrap;
}

.h-centered-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.h-justified-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.h-start-flex {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.h-end-flex {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}

.pagination-h-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  align-items: flex-start;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.responsive-flex-image {
  margin: 15px;
  width: 320px;
  height: 270px;
}

@media only screen and (max-width: 600px) {
  .responsive-flex-image {
    margin: 0 auto;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .responsive-flex-image {
    margin: 0 auto;
    padding: 15px;
    width: 70%;
  }
}

/* -------------------- Image center and cropped --------------------*/
.centered-and-cropped {
  object-fit: cover;
}

.profile-pic {
  height: 100%;
  width: 100%;
}

.rounded {
  border-radius: 50%;
}

/* ActivePath needed to position the button. Adjust the width as needed */
.profile-pic-container {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  margin: 0px;
  padding: 0px;
  position: relative;
}

/* Style the button and place it in the middle of the container/image */
.change-profile-pic {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -0%);
  height: 50%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
  border-bottom-left-radius: 100px !important;
  border-bottom-right-radius: 100px !important;
  font-size: 1.2em;
  cursor: pointer;
  border: 0;
}

.abs-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.change-profile-pic:hover {
  opacity: 1;
  transition: all 0.3s ease;
}

.change-profile-pic:focus {
  outline: none;
}

.pointer-change-on-hover:hover {
  cursor: pointer;
}

.color-change-on-hover:hover {
  color: #0284ca;
}

.faded {
  color: #a7abc3 !important;
}

.fade {
  opacity: 0.25;
}

.text-muted {
  color: #a7abc3 !important;
}

.text-medium {
  font-size: 1.12em;
}

.text-big {
  font-size: 1.2em;
}

.text-biggest {
  font-size: 1.35em;
}

.text-mini {
  font-size: 0.8em;
}

.text-alternate {
  font-family: "Roboto", sans-serif;
}

/*Margin and padding helpers*/
.small-padding {
  padding: 15px;
}

.small-vertical-padding {
  padding: 15px 0;
}

.medium-padding {
  padding: 30px;
}

.medium-vertical-padding {
  padding: 36px 0;
}

.default-margin {
  margin-bottom: 15px;
}

.default-margin-closer {
  margin-bottom: 7px;
}

.default-margin-farther {
  margin-bottom: 30px;
}

.no-margin {
  margin: 0;
}

.small-vertical-margin {
  margin: 5px 0;
}

.medium-vertical-margin {
  margin: 15px 0;
}

@media only screen and (min-width: 768px) {
  .ant-table-body {
    overflow-x: auto !important;
  }
}

.loading-container {
  height: 100vh;
  width: 100vw;
  background: white;
  position: fixed;
  opacity: 1;
}

.rounded-btn {
  border-radius: 5px;
}

._loading_overlay_wrapper {
  background: white;
}

._loading_overlay_overlay {
  background: white;
}

._loading_overlay_content {
  background: white;
}

/* -------------------------- Input ------------------------ */
.responsive-input {
  width: 250px;
}

.small-responsive-input {
  width: 120px;
}

@media only screen and (max-width: 600px) {
  .responsive-input,
  .small-responsive-input {
    width: 100%;
  }
}

/* -------------------- Table --------------------*/
.bg-white {
  background: white;
}

.diff-colored-bg-1 {
  background: #fafafa;
}

.colored-col-bg-1 {
  background: #b3e5fc;
}

.colored-col-bg-2 {
  background: #90a4ae;
}

.colored-col-bg-3 {
  background: #eff8fc;
}

.colored-col-bg-4 {
  background: #fcfcfc;
}

/* -------------------- Grid --------------------*/
.investment-cards-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Where the magic happens */
  grid-auto-rows: auto;
  grid-gap: 30px;
}

.stat-cards-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Where the magic happens */
  grid-auto-rows: auto;
  grid-gap: 30px;
}

/* -------------------- Table --------------------*/
.alternate-table {
  width: 100%;
}

.alternate-table tr:nth-child(odd) {
  background-color: #fafafa;
}

.alternate-table th {
  background: #fefefe;
}

.alternate-table td,
th {
  padding: 10px;
}

.alternate-table th {
  font-weight: 500;
}

.expanded-cell {
  width: 100%; /* this will shrink other cells */
}
​ .bordered-right {
  border-right: 1px solid #dee2e6;
}

.bordered-top {
  border-top: 1px solid #dee2e6;
}

.bordered-bottom {
  border-bottom: 1px solid #dee2e6;
}

@media only screen and (max-width: 600px) {
  .bordered-right {
    border-right: 0;
  }
}

/* -------------------- Single alternat etable style --------------------*/
.single-alternate-table-like {
  background-color: #fafafa;
  width: 100%;
  padding: 10px;
}

/* -------------------- Overflow --------------------*/
.overflow-word {
  overflow-wrap: break-word;
  word-break: break-word;
}

/* -------------------- Custom inner card --------------------*/
.custom-inner-card {
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  /*box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);*/
}

@media only screen and (max-width: 600px) {
  .custom-inner-card {
    /*border: 0;*/
  }
}

/* -------------------- Filter inputs --------------------*/
.filter-inputs {
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .filter-inputs {
    width: 100%;
  }
}

/* -------------------- print --------------------*/
@media print {
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 15mm 0 0 0;
  }

  body {
    /*padding-top:5mm;*/
  }
}

.print-table {
  font-family: Roboto, sans-serif;
  font-size: 10px;
  width: 95%;
}

.print-table td,
.print-table th {
  border: 1px solid #ddd;
  border-collapse: collapse;
  padding: 5px;
}

.print-table td div {
  padding: 7px;
}

.print-table tr:nth-child(even) {
  background-color: #fafafa;
}

.make-ant-input {
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 1em;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all 0.3s;
}

/* -------------------- hash or addresses --------------------*/
.hash-address {
  /*background-color: #FAFAFA;*/
  /*padding: 10px;*/
}

.inverted-div {
  background: #cfd8dc;
  color: black;
  /*font-weight: 600;*/
}

.inner-divs div {
  overflow: visible !important;
}

.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  vertical-align: middle;
}

.content-child {
  /*flex: 1 0 auto;*/
}

.my-svgs {
  fill: #009bc3;
}

.swal-title {
  font-size: 1.5rem !important;
  overflow-wrap: break-word;
  word-break: break-word;
}

.swal-small-title {
  font-size: 1.2rem !important;
  overflow-wrap: break-word;
  word-break: break-word;
  /*font-weight: 400;*/
}

/* -------------------- badge --------------------*/
.badge {
  padding: 6px 12px;
  line-height: 15px;
  font-weight: 400;
  min-width: 90px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid;
}

.whitespace-nowrap {
  white-space: nowrap;
}

/* -------------------- glowing blinking --------------------*/
.led-box {
  top: 35%;
  left: 10%;
  transform: translate(-50%, -35%);
  position: absolute;
}

.led-green {
  margin: 0 auto;
  width: 12px;
  height: 12px;
  background-color: #abff00;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 12px;
  -webkit-animation: blinkGreen 1s infinite;
  animation: blinkGreen 1s infinite;
}

@-webkit-keyframes blinkGreen {
  from {
    background-color: #abff00;
  }
  50% {
    background-color: #43a047;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 0;
  }
  to {
    background-color: #abff00;
  }
}
@keyframes blinkGreen {
  from {
    background-color: #abff00;
  }
  50% {
    background-color: #1b5e20;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #abff00 0 2px 0;
  }
  to {
    background-color: #abff00;
  }
}

/* -------------------- zig zag --------------------*/

.zag-out:after {
  top: 100%;
  background: linear-gradient(135deg, white 33.333%, transparent 0),
    linear-gradient(-135deg, white 33.333%, transparent 0);
}

.zag-in:after {
  bottom: 0;
  background: linear-gradient(45deg, white 33.333%, transparent 0),
    linear-gradient(-45deg, white 33.333%, transparent 0);
}

.zig {
  position: relative;
  background: white;
  /* Anything but 'static' will work here. */
}
.zig:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.5em;
  /*desired tooth height*/
  background-size: 1em 100%;
  /*width (1st num) must be twice tooth height*/
  background-position: center;
  /*optional: used for symmetrical edges*/
}

.blue-card {
  background-image: linear-gradient(45deg, #1c65c9 0%, #2c80ff 100%);
  color: #fff;
}

/* -------------------- table --------------------*/
.data-details-list {
  border-radius: 4px;
  border: 1px solid #d2dde9;
}

.data-transaction-list {
  border-radius: 4px;
  border: 1px solid #d2dde9;
}

.data-transaction-item {
  border-bottom: 1px solid #d2dde9;
  display: inline-block;
  width: 100%;
}

.data-details-head {
  font-weight: 500;
  color: #758698;
  line-height: 24px;
  padding: 15px 15px 2px 15px;
  width: 100%;
  display: inline-block;
}

@media (min-width: 576px) {
  .data-details-head {
    width: 190px;
    padding: 15px;
  }
}

@media (min-width: 768px) {
  .data-details-head {
    width: 190px;
  }
}

.data-details-list div:last-child .data-details-des {
  border-bottom: none;
}

.data-transaction-list div div:last-child .data-transaction-item {
  border-bottom: none;
}

.data-details-des {
  color: #495463;
  line-height: 24px;
  padding: 2px 15px 15px 15px;
  border-bottom: 1px solid #d2dde9;
  display: inline-block;
  width: 100%;
}

@media (min-width: 576px) {
  .data-details-des {
    border-top: none;
    line-height: 24px;
    border-left: 1px solid #d2dde9;
    width: calc(100% - 190px);
    padding: 15px;
  }
}

.placeholder {
  width: 36px;
  height: 36px;
  margin-right: 7px;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-size: contain;
  background: white;
  /*background-image: url("./static/profile-pic-placeholder.jpeg");*/
}

/* Overrides */

.ant-table-thead > tr > th {
  /*background: rgba(6, 103, 208, .8) !important;*/
  /*color: #009bc3 !important;*/
}

.ant-table-thead > tr > th > div {
  /*color: white;*/

}

.ant-input {
  /*border-radius: 0 !important;*/
}

#nprogress .bar {
  background: #f28e38 !important;

  z-index: 1031;
  top: 0 !important;
  left: 0;

  width: 100%;
  height: 5px !important;
}

/* Fancy blur effect */
#nprogress .peg {
  /*box-shadow: 0 0 10px #f28e38, 0 0 5px #29d !important;*/
  box-shadow: none !important;
}

/* Fancy blur effect */
#nprogress .spinner {
  display: none !important;
}

.ant-divider-horizontal {
  margin: 0 0 15px 0 !important;
}

.ant-progress-inner {
  display: inline-block;
  width: 100%;
  background-color: #E0E0E0 !important;
  border-radius: 100px;
  vertical-align: middle;
  position: relative;
}

.ant-tag {
  height: 22px !important;
}

.ant-calendar-picker {
  max-width: 200px;
}

@media only screen and (max-width: 600px) {
  .ant-radio-button-wrapper {
    padding: 0 5px !important;
  }

  .ant-steps-item-title {
    width: 100%;
  }
}


.ant-skeleton-paragraph {
  display: none;
}

/* -------------------- tabs --------------------*/
/* -------------------- tabs container --------------------*/
#tabs-card-container > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
}

#tabs-card-container > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: #F5F5F5;
  padding: 15px;
}

#tabs-card-container > .ant-tabs-card > .ant-tabs-bar {
  border-color: #F5F5F5;
}

#tabs-card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}

#tabs-card-container > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #F5F5F5;
  background: #F5F5F5;
}

.ant-tabs-tabpane-inactive {
  /*display: none;*/
}


/* -------------------- Input number --------------------*/
input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.ant-input-number-handler-wrap {
  display: none;
}

.side-drawer .ant-drawer-header {
  background: #009bc3;
  border-radius: 0;
}

.ant-tabs {
  /*font-size: 14px !important;*/
}

.ant-table {
  /*font-size: 13px !important;*/
}


/* -------------------- message --------------------*/
.ant-message-notice-content {
  overflow-wrap: break-word;
  word-break: break-word;
}

/* -------------------- Alerts --------------------*/
.ant-alert-close-icon {
  font-size: 16px !important;
  font-weight: bold !important;
  right: 10px !important;
}

.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: green !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: green !important;
}

.ant-steps-item-finish > .ant-steps-item-tail::after {
  background-color: #52c41a !important;
}

.ant-layout-content {
  /*display: flex;*/
}

/* -------------------- slider --------------------*/
#custom-progress span, #custom-progress div {
  cursor: default !important;
}

#custom-progress .ant-slider-track {
  background: #009AC2 !important;
}

#custom-progress .ant-slider-handle, #custom-progress  .ant-slider-dot {
  border-color: #009AC2 !important;
}


.loader-css {
  display: block;
  margin: 0 auto;
  border-color: red;
}
.toast-title {
  font-weight: bold;
  font-size: 1.2rem;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1;
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  top: 15px;
  position: fixed;
  z-index: 999999;
  pointer-events: none;
  /*overrides*/
}

#toast-container * {
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  /*border-radius: 3px 3px 3px 3px;*/
  background-position: 15px center;
  background-repeat: no-repeat;
  /*-moz-box-shadow: 0 0 12px #999999;*/
  /*-webkit-box-shadow: 0 0 12px #999999;*/
  /*box-shadow: 0 0 12px #999999;*/
  color: #FFFFFF;
  opacity: 0.9;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);

  font-weight: 500;

  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}

#toast-container > div:hover {
  /*-moz-box-shadow: 0 0 12px #000000;*/
  /*-webkit-box-shadow: 0 0 12px #000000;*/
  /*box-shadow: 0 0 12px #000000;*/
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important;
}

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important;
}

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
}

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }

  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }

  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }

  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}

.constrained-720 {
  width: 1180px;
}


@media only screen and (max-width: 1200px) {
  .constrained-720 {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  #my-nav .ant-menu-item, #my-nav .ant-menu-submenu {
    /*line-height: 40px !important;*/
    padding: 5px 7px !important;
    /*margin: 0 0 1px 0 !important;*/
  }

  #my-nav .ant-menu-submenu-title {
    padding: 0 !important;
    margin: 0 !important;
  }

  .ant-menu-sub > .ant-menu-item > a, .ant-menu-sub > .ant-menu-item > a > span  {
    height: 40px !important;
  }
}


@media only screen and (max-width: 599px) {
  #my-nav .ant-menu-item, #my-nav .ant-menu-submenu {
    line-height: 40px !important;
    padding: 0 34px 0 24px !important;
    margin: 4px 0 !important;
  }

  #my-nav .ant-menu-sub > .ant-menu-item > a, #my-nav .ant-menu-item > a > span{
    line-height: 40px !important;
    vertical-align: center;
  }

  #my-nav .ant-menu-item > a > span{
    margin: 0 7px !important;
  }

  #my-nav .ant-menu-submenu-title {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.side-menu > .anticon, .side-menu > .anticon {
  font-size: 1.2em !important;
  margin-right: 7px;
}

.internet-error {
  height: 24px;
  background: #ef5350;
  margin-top: 0;
}

.internet-error p {
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  margin: 0;
  text-align:center;
}

