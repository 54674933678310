.internet-error {
  height: 24px;
  background: #ef5350;
  margin-top: 0;
}

.internet-error p {
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  margin: 0;
  text-align:center;
}
