@media only screen and (min-width: 600px) {
  #my-nav .ant-menu-item, #my-nav .ant-menu-submenu {
    /*line-height: 40px !important;*/
    padding: 5px 7px !important;
    /*margin: 0 0 1px 0 !important;*/
  }

  #my-nav .ant-menu-submenu-title {
    padding: 0 !important;
    margin: 0 !important;
  }

  .ant-menu-sub > .ant-menu-item > a, .ant-menu-sub > .ant-menu-item > a > span  {
    height: 40px !important;
  }
}


@media only screen and (max-width: 599px) {
  #my-nav .ant-menu-item, #my-nav .ant-menu-submenu {
    line-height: 40px !important;
    padding: 0 34px 0 24px !important;
    margin: 4px 0 !important;
  }

  #my-nav .ant-menu-sub > .ant-menu-item > a, #my-nav .ant-menu-item > a > span{
    line-height: 40px !important;
    vertical-align: center;
  }

  #my-nav .ant-menu-item > a > span{
    margin: 0 7px !important;
  }

  #my-nav .ant-menu-submenu-title {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.side-menu > .anticon, .side-menu > .anticon {
  font-size: 1.2em !important;
  margin-right: 7px;
}
